var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row books"
  }, [_c('div', {
    staticClass: "col-md-12 mt-2"
  }, [_c('div', {
    staticClass: "card"
  }, [_vm.isError ? _c('b-row', {
    staticClass: "justify-content-center mt-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "10"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": "",
      "dismissible": ""
    }
  }, [_vm._v(" Opps .. Something is wrong " + _vm._s(_vm.errorMessage) + " ")])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "card-body"
  }, [_c('h4', {
    staticClass: "text-center"
  }, [_vm._v("Subscription Report")]), _c('b-alert', {
    attrs: {
      "show": ""
    }
  }, [_vm._v(" Laporan penjualan berdasarkan Subscription Offers. ( Exclude Payment Free, Wallet, Gramedia.com ) ")]), _c('div', {
    staticClass: "form-group row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-sm-6"
  }, [_c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Type to search",
      "open-direction": "bottom",
      "options": _vm.items_search,
      "searchable": true,
      "loading": _vm.isLoadingSearch,
      "close-on-select": true,
      "options-limit": 10,
      "limit": 10,
      "custom-label": _vm.customLabel
    },
    on: {
      "search-change": _vm.asyncFind
    },
    scopedSlots: _vm._u([{
      key: "tag",
      fn: function (_ref) {
        var option = _ref.option,
          remove = _ref.remove;
        return [_c('span', {
          staticClass: "custom__tag"
        }, [_c('span', [_vm._v(_vm._s(option.voucher_code))]), _c('span', {
          staticClass: "custom__remove",
          on: {
            "click": function ($event) {
              return remove(option);
            }
          }
        }, [_vm._v("❌")])])];
      }
    }, {
      key: "clear",
      fn: function (props) {
        return [_vm.filter.length ? _c('div', {
          staticClass: "multiselect__clear",
          on: {
            "mousedown": function ($event) {
              $event.preventDefault();
              $event.stopPropagation();
              return _vm.clearAll(props.search);
            }
          }
        }) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)]), _c('div', {
    staticClass: "form-group row"
  }, [_c('span', {
    staticClass: "col-sm-2"
  }, [_vm._v("Select Range")]), _c('div', {
    staticClass: "col-md-8"
  }, [_c('v-date-picker', {
    attrs: {
      "mode": "date",
      "masks": _vm.masks,
      "is-range": "",
      "max-date": new Date(),
      "color": "purple"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var inputValue = _ref2.inputValue,
          inputEvents = _ref2.inputEvents,
          isDragging = _ref2.isDragging;
        return [_c('div', {
          staticClass: "rows"
        }, [_c('input', _vm._g({
          staticClass: "flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1",
          class: isDragging ? 'text-gray-600' : 'text-gray-900',
          attrs: {
            "color": "purple",
            "max-date": new Date()
          },
          domProps: {
            "value": inputValue.start
          }
        }, inputEvents.start)), _c('span', {
          staticClass: "p-2"
        }, [_vm._v("to")]), _c('input', _vm._g({
          staticClass: "flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1",
          class: isDragging ? 'text-gray-600' : 'text-gray-900',
          attrs: {
            "color": "purple",
            "max-date": new Date()
          },
          domProps: {
            "value": inputValue.end
          }
        }, inputEvents.end))])];
      }
    }]),
    model: {
      value: _vm.range,
      callback: function ($$v) {
        _vm.range = $$v;
      },
      expression: "range"
    }
  })], 1), _c('div', {
    staticClass: "col-md-2"
  }, [_c('button', {
    staticClass: "btn btn-block btn-primary",
    on: {
      "click": _vm.actionSearch
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  })])])]), _c('div', {
    staticClass: "row justify-content-end"
  }, [_c('div', {
    staticClass: "col-md-2 mb-1"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.curs,
      expression: "curs"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "14000"
    },
    domProps: {
      "value": _vm.curs
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.curs = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "col-md-1 mb-1"
  }, [_c('button', {
    staticClass: "btn btn-block btn-outline-danger",
    on: {
      "click": _vm.actionCreateTablePDF
    }
  }, [_c('i', {
    staticClass: "fa fa-file-pdf-o"
  })])]), _c('div', {
    staticClass: "col-md-2"
  }, [_c('label', {
    staticClass: "col-sm-auto",
    attrs: {
      "for": ""
    }
  }, [_vm._v("Count ")]), _c('strong', {
    staticClass: "col-sm-auto"
  }, [_vm._v(_vm._s(_vm.formatPriceBasic(_vm.totalRows)))])])]), _c('table', {
    staticClass: "table table-striped table-bordered table-sm table-responsive-sm mt-4"
  }, [_vm._m(1), !_vm.isLoading ? _c('tbody', [_vm._l(_vm.subscription, function (item, index) {
    return [_c('tr', {
      key: item.id
    }, [_c('td', [_vm._v(_vm._s(++index))]), _c('td', [_vm._v(_vm._s(item.name))]), _c('td', {
      staticClass: "text-right"
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.price, item.currency_code)) + " ")]), _c('td', [_c('span', {
      class: [item.is_discount ? _vm.activeClass : _vm.errorClass]
    }, [_vm._v(" " + _vm._s(item.is_discount ? "Ya" : "No") + " ")])]), _c('td', {
      staticClass: "text-right"
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.final_price, item.currency_code)) + " ")]), _c('td', [_c('span', {
      class: [item.is_free ? _vm.activeClass : _vm.errorClass]
    }, [_vm._v(" " + _vm._s(item.is_free ? "Ya" : "No") + " ")])]), _c('td', {
      staticStyle: {
        "width": "10%"
      }
    }, [_vm._v(_vm._s(_vm.formatDate(item.created)))]), _c('td', [_c('PaymentGateway', {
      attrs: {
        "gateway": item.paymentgateway_id
      }
    })], 1), _c('td', {
      staticClass: "text-right"
    }, [_vm._v(" " + _vm._s(_vm.FormatFormula(_vm.PaymentCost(item.paymentgateway_id))) + " ")]), _c('td', {
      staticClass: "text-right"
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.CountAmountCost(item.final_price, 1, item.paymentgateway_id), item.currency_code)) + " ")]), item.currency_code == 'USD' ? _c('td', {
      staticClass: "text-right"
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.reversIDR(_vm.finalAmount(item.final_price, _vm.CountAmountCost(item.final_price, 1, item.paymentgateway_id))), "IDR")) + " ")]) : _c('td', {
      staticClass: "text-right"
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.finalAmount(item.final_price, _vm.CountAmountCost(item.final_price, 1, item.paymentgateway_id)), item.currency_code)) + " ")])])];
  })], 2) : _c('tbody', [[_c('tr', [_c('td', {
    attrs: {
      "colspan": "10"
    }
  }, [_c('div', {
    staticClass: "text-center text-danger my-2"
  }, [_c('b-spinner', {
    staticClass: "align-middle"
  }), _c('strong', [_vm._v(" Loading...")])], 1)])])]], 2), _c('tfoot', [_c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("Total")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.SumPrice(_vm.totalAmountD * _vm.curs, _vm.totalAmount), "IDR")) + " ")]), _c('td', {
    attrs: {
      "colspan": "4"
    }
  }), _c('td', {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.gatewayCost, "IDR")) + " ")]), _c('td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.SumPrice(_vm.balanceIDR, _vm.balanceUSD), "IDR")) + " ")])]), _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "10"
    }
  }, [_vm._v("Total Revenue (Balance * " + _vm._s(_vm.revenue && _vm.revenue.length > 0 ? _vm.revenue[0].default_percentage_amount : '') + " )")]), _c('td', {
    staticClass: "text-right"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.SumRevenue(_vm.SumPrice(_vm.balanceIDR, _vm.balanceUSD), _vm.revenue && _vm.revenue.length > 0 ? _vm.revenue[0].default_percentage_amount : ''), "IDR")) + " ")])])])])])], 1), _c('b-row', {
    staticClass: "justify-content-start ml-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "col-sm-2 form-control-label",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Vendor "), _c('br')]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("item Name")]), _c('th', [_vm._v("Price")]), _c('th', [_vm._v("Discount")]), _c('th', [_vm._v("Final Price")]), _c('th', [_vm._v("Free")]), _c('th', [_vm._v("Purchase Date")]), _c('th', [_vm._v("Payment")]), _c('th', [_vm._v("Gateway Fee")]), _c('th', [_vm._v("Gateway Cost")]), _c('th', [_vm._v("Balance")])])]);
}]

export { render, staticRenderFns }